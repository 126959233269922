import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref } from 'vue';
export default {
  __name: 'CardWheel',
  emits: ['endCallback'],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const blocks = [{
      padding: '27px',
      imgs: [{
        src: '/static/imgs/zp_bg1.png',
        width: '100%',
        height: '100%'
      }]
    }, {
      imgs: [{
        src: '/static/imgs/zp_bg2.png',
        width: '100%',
        height: '100%',
        rotate: true
      }]
    }];
    const emit = __emit;
    const prizeImg = {
      src: '/static/imgs/prize.png',
      width: '40%',
      top: '25%'
    };
    const prizes = [{
      background: 'transparent',
      imgs: [prizeImg]
    }, {
      background: 'transparent',
      imgs: [prizeImg]
    }, {
      background: 'transparent',
      imgs: [prizeImg]
    }, {
      background: 'transparent',
      imgs: [prizeImg]
    }, {
      background: 'transparent',
      imgs: [prizeImg]
    }, {
      background: 'transparent',
      imgs: [prizeImg]
    }];
    const buttons = [{
      radius: '50%',
      imgs: [{
        src: '/static/imgs/wheel_btn.png',
        width: '98px',
        top: '-115%'
      }]
    }];
    const myLucky = ref(null);
    const startCallback = () => {
      // prizes.value[0].fonts[0].text = '300'
      // 调用抽奖组件的play方法开始游戏
      myLucky.value.play();
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const index = 0;
        // 调用stop停止旋转并传递中奖索引
        myLucky.value.stop(index);
      }, 3000);
    };
    const endCallback = prize => {
      emit('endCallback', prize);
    };
    __expose({
      startCallback
    });
    return (_ctx, _cache) => {
      const _component_LuckyWheel = _resolveComponent("LuckyWheel");
      return _openBlock(), _createBlock(_component_LuckyWheel, {
        ref_key: "myLucky",
        ref: myLucky,
        width: "338px",
        height: "338px",
        prizes: prizes,
        blocks: blocks,
        buttons: buttons,
        onStart: startCallback,
        onEnd: endCallback
      }, null, 512);
    };
  }
};