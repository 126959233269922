import "core-js/modules/es.array.push.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, unref as _unref, withModifiers as _withModifiers, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue";
import _imports_0 from '@/assets/images/order.png';
import _imports_1 from '@/assets/images/wallet.png';
import _imports_2 from '@/assets/images/avatar1.png';
import _imports_3 from '@/assets/images/avatar2.png';
import _imports_4 from '@/assets/images/notices.png';
import _imports_5 from '@/assets/images/booking/no1.png';
import _imports_6 from '@/assets/images/booking/no2.png';
import _imports_7 from '@/assets/images/booking/no3.png';
import _imports_8 from '@/assets/images/booking/no4.png';
import _imports_9 from '@/assets/images/booking/no5.png';
import _imports_10 from '@/assets/images/booking/no6.png';
import _imports_11 from '@/assets/images/booking/no7.png';
import _imports_12 from '@/assets/images/booking/no8.png';
import _imports_13 from '@/assets/images/booking/no9.png';
import _imports_14 from '@/assets/images/down1.png';
import _imports_15 from '@/assets/images/top.png';
import _imports_16 from '@/assets/images/booking/details01.png';
import _imports_17 from '@/assets/images/booking/details02.png';
import _imports_18 from '@/assets/images/booking/details03.png';
import _imports_19 from '@/assets/images/booking/details04.png';
import _imports_20 from '@/assets/images/booking/details05.png';
import _imports_21 from '@/assets/images/booking/details06.png';
import _imports_22 from '@/assets/images/booking/star.png';
import _imports_23 from '@/assets/images/booking/btn.png';
import _imports_24 from '@/assets/images/booking/like.png';
import _imports_25 from '@/assets/images/booking/card_bg.png';
const _hoisted_1 = {
  class: "booking-index"
};
const _hoisted_2 = {
  class: "booking-nav__right"
};
const _hoisted_3 = {
  class: "cash-wheel__box"
};
const _hoisted_4 = {
  class: "cash-wheel__title"
};
const _hoisted_5 = {
  class: "cash-wheel__box"
};
const _hoisted_6 = {
  class: "cash-wheel__title"
};
const _hoisted_7 = {
  class: "cash-wheel__title"
};
const _hoisted_8 = {
  class: "card-res__box"
};
const _hoisted_9 = {
  class: "card-res__title"
};
const _hoisted_10 = {
  class: "card-res__text"
};
const _hoisted_11 = {
  class: "cash-wheel__title"
};
const _hoisted_12 = {
  class: "card-res__box"
};
const _hoisted_13 = {
  class: "card-res__title"
};
const _hoisted_14 = {
  class: "card-res__text"
};
const _hoisted_15 = {
  class: "booking-index__content"
};
const _hoisted_16 = {
  class: "booking-index__head"
};
const _hoisted_17 = {
  class: "p2"
};
const _hoisted_18 = {
  class: "notice-box"
};
const _hoisted_19 = {
  class: "booking-index__container"
};
const _hoisted_20 = {
  class: "booking-index__row"
};
const _hoisted_21 = {
  class: "booking-index__row"
};
const _hoisted_22 = {
  class: "booking-index__row"
};
const _hoisted_23 = {
  key: 0,
  src: _imports_14,
  alt: ""
};
const _hoisted_24 = {
  key: 1,
  src: _imports_15,
  alt: ""
};
const _hoisted_25 = {
  class: "booking-foot__box"
};
const _hoisted_26 = {
  class: "booking-foot__title"
};
const _hoisted_27 = {
  class: "booking-order__title"
};
const _hoisted_28 = {
  class: "booking-order__media"
};
const _hoisted_29 = ["src"];
const _hoisted_30 = ["src"];
const _hoisted_31 = {
  class: "booking-order__details"
};
const _hoisted_32 = {
  class: "booking-order__details--head"
};
const _hoisted_33 = {
  class: "booking-order__points--box"
};
const _hoisted_34 = {
  class: "booking-order__points"
};
const _hoisted_35 = {
  key: 0
};
const _hoisted_36 = {
  class: "van-ellipsis"
};
const _hoisted_37 = {
  class: "booking-order__text"
};
const _hoisted_38 = {
  class: "booking-order__item"
};
const _hoisted_39 = {
  class: "booking-order__item"
};
const _hoisted_40 = {
  class: "booking-order__item"
};
const _hoisted_41 = {
  class: "booking-order__item"
};
const _hoisted_42 = {
  class: "booking-order__item"
};
const _hoisted_43 = {
  key: 0,
  class: "booking-btn__img"
};
const _hoisted_44 = {
  class: "booking-order__title"
};
const _hoisted_45 = {
  class: "booking-order__media"
};
const _hoisted_46 = ["src"];
const _hoisted_47 = {
  class: "booking-order__details"
};
const _hoisted_48 = {
  class: "booking-order__details--head"
};
const _hoisted_49 = {
  class: "booking-order__points--box"
};
const _hoisted_50 = {
  class: "booking-order__points"
};
const _hoisted_51 = {
  class: "van-ellipsis"
};
const _hoisted_52 = {
  class: "booking-order__text"
};
const _hoisted_53 = {
  class: "booking-order__item"
};
const _hoisted_54 = {
  class: "booking-order__item"
};
const _hoisted_55 = {
  class: "booking-order__item"
};
const _hoisted_56 = {
  class: "van-ellipsis",
  style: {
    "padding-left": "32px"
  }
};
const _hoisted_57 = {
  class: "booking-order__item"
};
const _hoisted_58 = {
  class: "booking-order__item"
};
const _hoisted_59 = {
  class: "booking-order__item"
};
const _hoisted_60 = {
  class: "booking-confirm__box"
};
const _hoisted_61 = {
  class: "booking-confirm__content"
};
const _hoisted_62 = {
  class: "booking-confirm__btns"
};
const _hoisted_63 = {
  class: "booking-card__p"
};
const _hoisted_64 = {
  class: "card-back"
};
const _hoisted_65 = {
  key: 0,
  class: "mb-12 fw-700"
};
const _hoisted_66 = {
  class: "card-back__text"
};
const _hoisted_67 = {
  class: "card-back"
};
const _hoisted_68 = {
  key: 0,
  class: "mb-12 fw-700"
};
const _hoisted_69 = {
  class: "card-back__text"
};
const _hoisted_70 = {
  class: "card-back"
};
const _hoisted_71 = {
  key: 0,
  class: "mb-12 fw-700"
};
const _hoisted_72 = {
  class: "card-back__text"
};
const _hoisted_73 = {
  class: "booking-wallet__"
};
const _hoisted_74 = {
  class: "booking-wallet__title"
};
const _hoisted_75 = {
  class: "booking-wallet__bouns"
};
const _hoisted_76 = {
  style: {
    "padding": "0 24px"
  }
};
const _hoisted_77 = {
  class: "booking-balance__label"
};
const _hoisted_78 = {
  class: "booking-balance__amount"
};
const _hoisted_79 = {
  class: "booking-wallet__foot"
};
const _hoisted_80 = {
  class: "label"
};
const _hoisted_81 = {
  class: "value"
};
const _hoisted_82 = {
  class: "label"
};
const _hoisted_83 = {
  class: "value"
};
const _hoisted_84 = {
  class: "label"
};
const _hoisted_85 = {
  class: "value"
};
import { router } from '@/router';
import { computed, ref, watch } from 'vue';
import { BroadcastList, Buy, ConfirmOrder } from '@/api';
import { baseURL } from '@/config';
import { showLoadingToast, showToast } from 'vant';
import PayPassword from '@/components/PayPassword.vue';
import { useUserStore } from '@/store/user';
import dayjs from 'dayjs';
import CashWheel from '@/components/CashWheel.vue';
export default {
  __name: 'Index',
  setup(__props) {
    const userStore = useUserStore();
    userStore.updateUserInfo();
    const user = computed(() => userStore.user);
    const showFoot = ref(false);
    const showOrder = ref(false);
    const showConfirm = ref(false);
    const showCard = ref(false);
    const showMsg = ref(false);
    const showWallet = ref(false);
    const orderData = ref({});
    const onClickLeft = () => {
      router.back();
    };
    const showFootHandle = () => {
      showFoot.value = !showFoot.value;
      if (showFoot.value) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    };
    const toBookingRecord = () => {
      router.push({
        name: 'BookingRecord'
      });
    };
    const broadcastList = ref('');
    const card1 = ref(null);
    const card2 = ref(null);
    const card3 = ref(null);
    const cardDom = ref(null);
    const winIndex = ref(null);
    const cardData = ref([]);
    const showCashWheel = ref(false);
    const showCardWheel = ref(false);
    const _BroadcastList = () => {
      BroadcastList().then(({
        code,
        data
      }) => {
        if (code === 200) {
          data.forEach(item => {
            broadcastList.value += item.content + " ";
          });
        }
      });
    };
    _BroadcastList();
    const prizes = ref([{
      fonts: [{
        text: '300',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }, {
      fonts: [{
        text: '200',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }, {
      fonts: [{
        text: '220',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }, {
      fonts: [{
        text: '240',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }, {
      fonts: [{
        text: '260',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }, {
      fonts: [{
        text: '280',
        top: '35%',
        fontSize: '22px',
        fontWeight: 'bold'
      }]
    }]);
    const cashWheelRef = ref(null);
    const cardWheelRef = ref(null);
    const showCardRes = ref(false);
    const showCashRes = ref(false);
    const wonTitle = ref('');
    const prizeMoney = ref('');
    const _Buy = event => {
      const card = event.currentTarget;
      cardDom.value = card;

      // const data = {
      //   "type": 3,
      //   "order_no": "1726734543610",
      //   "order_time": 1726734543,
      //   "order_price": 0,
      //   "total_return": 0,
      //   "commit": "",
      //   "info": {
      //     "id": 5,
      //     "user_id": 21,
      //     "type": 3,
      //     "task_sort": 6,
      //     "prize": "1",
      //     "status": 2,
      //     "remark": null,
      //     "no_win1_type": "2",
      //     "no_win1_prize": "1",
      //     "no_win2_type": "1",
      //     "no_win2_prize": "100",
      //     "created_at": "2024-09-19 07:45:35",
      //     "updated_at": "2024-09-19 13:59:03",
      //     "pack_id": null,
      //     "category_id": null,
      //     "start_time": null
      //   }
      // }
      // orderData.value = data
      // card.classList.add('rotate')
      // setTimeout(() => {
      //   if (data.type === 1 || data.type === 2) {
      //     showOrder.value = true
      //   }
      //   if (data.type === 3 && data.info.type === 3) {
      //     wonTitle.value = orderData.value.info.type == 2 ? orderData.value.info.pack.title : orderData.value.info.prize
      //     showCardWheel.value = true
      //     setTimeout(() => {
      //       cardWheelRef.value.startCallback()
      //     }, 1000)
      //   }
      //   if (data.type === 3 && data.info.type === 1) {
      //     prizes.value[0].fonts[0].text = data.info.prize
      //     prizes.value[1].fonts[0].text = data.info.no_win1_prize
      //     prizes.value[2].fonts[0].text = data.info.no_win2_prize
      //     prizes.value[3].fonts[0].text = data.info.no_win3_prize
      //     prizes.value[4].fonts[0].text = data.info.no_win4_prize
      //     prizes.value[5].fonts[0].text = data.info.no_win5_prize
      //     showCashWheel.value = true
      //     setTimeout(() => {
      //       cashWheelRef.value.startCallback()
      //     }, 1000)
      //   }
      // }, 1000); // 与 CSS 动画持续时间相匹配
      // return false;

      Buy().then(({
        msg,
        code,
        data
      }) => {
        if (code === 200) {
          orderData.value = data;
          card.classList.add('rotate');
          setTimeout(() => {
            if (data.type === 1 || data.type === 2) {
              showOrder.value = true;
            }
            if (data.type === 3) {
              wonTitle.value = orderData.value.info.type == 2 ? orderData.value.info.pack.title : orderData.value.info.prize;
              showCardWheel.value = true;
              setTimeout(() => {
                cardWheelRef.value.startCallback();
              }, 1000);
            }
            if (data.type === 4) {
              prizeMoney.value = data.info.prize;
              prizes.value[0].fonts[0].text = data.info.prize;
              prizes.value[1].fonts[0].text = data.info.no_win1_prize;
              prizes.value[2].fonts[0].text = data.info.no_win2_prize;
              prizes.value[3].fonts[0].text = data.info.no_win3_prize;
              prizes.value[4].fonts[0].text = data.info.no_win4_prize;
              prizes.value[5].fonts[0].text = data.info.no_win5_prize;
              showCashWheel.value = true;
              setTimeout(() => {
                cashWheelRef.value.startCallback();
              }, 1000);
            }
          }, 1000); // 与 CSS 动画持续时间相匹配
        } else {
          showToast(msg);
        }
      });
    };
    const showPayPwd = ref(false);
    const _ConfirmOrder = () => {
      // showPayPwd.value = true
      payPwdHandle();
    };
    const payPwdHandle = () => {
      showLoadingToast();
      ConfirmOrder().then(({
        code,
        msg
      }) => {
        showToast(msg);
        if (code === 200) {
          showPayPwd.value = false;
          showOrder.value = false;
          showKaDan.value = false;
          cardDom.value.classList.remove('rotate');
          setTimeout(() => {
            cardDom.value.classList.add('reset');
          }, 1500);
        }
      });
    };
    watch(showCard, val => {
      if (!val && orderData.value.info.type != 2) {
        cardDom.value.classList.remove('rotate');
        setTimeout(() => {
          cardDom.value.classList.add('reset');
        }, 1500);
      }
    });
    watch(showOrder, val => {
      if (!val && orderData.value.info.type != 2) {
        cardDom.value.classList.remove('rotate');
        setTimeout(() => {
          cardDom.value.classList.add('reset');
        }, 1500);
      }
    });
    const showKaDan = ref(false);
    const tapCard = index => {
      if (!winIndex.value) {
        winIndex.value = index;
      }
      switch (index) {
        case 0:
          if (cardData.value.length === 0) {
            cardData.value[0] = {
              title: orderData.value.info.type == 2 ? orderData.value.info.pack.title : orderData.value.info.prize
            };
            cardData.value[1] = {
              title: orderData.value.info.no_win1_prize
            };
            cardData.value[2] = {
              title: orderData.value.info.no_win2_prize
            };
          }
          card1.value.classList.add('card-active');
          if (card2.value.classList.contains('card-active')) {
            card2.value.classList.add('card-active1');
          }
          if (card3.value.classList.contains('card-active')) {
            card3.value.classList.add('card-active1');
          }
          setTimeout(() => {
            kaDan();
            card1.value.classList.add('card-active1');
            if (!card2.value.classList.contains('card-active')) {
              card2.value.classList.add('card-active1');
            }
            if (!card3.value.classList.contains('card-active')) {
              card3.value.classList.add('card-active1');
            }
          }, 3000);
          break;
        case 1:
          if (cardData.value.length === 0) {
            cardData.value[1] = {
              title: orderData.value.info.type == 2 ? orderData.value.info.pack.title : orderData.value.info.prize
            };
            cardData.value[0] = {
              title: orderData.value.info.no_win1_prize
            };
            cardData.value[2] = {
              title: orderData.value.info.no_win2_prize
            };
          }
          card2.value.classList.add('card-active');
          if (card1.value.classList.contains('card-active')) {
            card1.value.classList.add('card-active1');
          }
          if (card3.value.classList.contains('card-active')) {
            card3.value.classList.add('card-active1');
          }
          setTimeout(() => {
            kaDan();
            card2.value.classList.add('card-active1');
            if (!card1.value.classList.contains('card-active')) {
              card1.value.classList.add('card-active1');
            }
            if (!card3.value.classList.contains('card-active')) {
              card3.value.classList.add('card-active1');
            }
          }, 3000);
          break;
        case 2:
          if (cardData.value.length === 0) {
            cardData.value[2] = {
              title: orderData.value.info.type == 2 ? orderData.value.info.pack.title : orderData.value.info.prize
            };
            cardData.value[1] = {
              title: orderData.value.info.no_win1_prize
            };
            cardData.value[0] = {
              title: orderData.value.info.no_win2_prize
            };
          }
          card3.value.classList.add('card-active');
          if (card2.value.classList.contains('card-active')) {
            card2.value.classList.add('card-active1');
          }
          if (card1.value.classList.contains('card-active')) {
            card1.value.classList.add('card-active1');
          }
          setTimeout(() => {
            kaDan();
            card3.value.classList.add('card-active1');
            if (!card2.value.classList.contains('card-active')) {
              card2.value.classList.add('card-active1');
            }
            if (!card1.value.classList.contains('card-active')) {
              card1.value.classList.add('card-active1');
            }
          }, 3000);
          break;
      }
    };
    const kaDan = () => {
      setTimeout(() => {
        showCard.value = false;
        showKaDan.value = true;
      }, 1000);
    };
    const endCallback = () => {
      showCashRes.value = true;
      // setTimeout(() => {
      //   showCashWheel.value = false
      //   cardDom.value.classList.remove('rotate');
      //   setTimeout(() => {
      //     cardDom.value.classList.add('reset');
      //   }, 1500)
      // }, 1000)
    };
    const _endCallback = () => {
      showCardRes.value = true;
    };
    const _closeCardRes = () => {
      showCardRes.value = false;
      showCardWheel.value = false;
      cardDom.value.classList.remove('rotate');
      setTimeout(() => {
        cardDom.value.classList.add('reset');
      }, 1500);
    };
    const _closeCashRes = () => {
      showCashRes.value = false;
      showCashWheel.value = false;
      cardDom.value.classList.remove('rotate');
      setTimeout(() => {
        cardDom.value.classList.add('reset');
      }, 1500);
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_overlay = _resolveComponent("van-overlay");
      const _component_CardWheel = _resolveComponent("CardWheel");
      const _component_van_notice_bar = _resolveComponent("van-notice-bar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(PayPassword, {
        "show-password": showPayPwd.value,
        onSubmit: payPwdHandle,
        onClose: _cache[0] || (_cache[0] = $event => showPayPwd.value = false)
      }, null, 8, ["show-password"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        class: "nav-grey__bg",
        fixed: "",
        placeholder: "",
        title: _ctx.$t('Task page'),
        "left-arrow": "",
        border: false,
        onClickLeft: onClickLeft
      }, {
        right: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("img", {
          src: _imports_0,
          alt: "",
          onClick: _cache[1] || (_cache[1] = $event => toBookingRecord())
        }), _createElementVNode("img", {
          src: _imports_1,
          alt: "",
          onClick: _cache[2] || (_cache[2] = $event => showWallet.value = true)
        })])]),
        _: 1
      }, 8, ["title"]), _createVNode(_component_van_overlay, {
        show: showCashWheel.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Take a REWARD')), 1), _createVNode(CashWheel, {
          ref_key: "cashWheelRef",
          ref: cashWheelRef,
          prizes: prizes.value,
          onEndCallback: endCallback
        }, null, 8, ["prizes"])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showCardWheel.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('Take a REWARD')), 1), _createVNode(_component_CardWheel, {
          ref_key: "cardWheelRef",
          ref: cardWheelRef,
          onEndCallback: _endCallback
        }, null, 512)])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showCardRes.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "cash-wheel__box",
          onClick: _closeCardRes
        }, [_createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('Take a REWARD')), 1), _createElementVNode("div", _hoisted_8, [_createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('Congratulati')), 1), _createElementVNode("p", _hoisted_10, _toDisplayString(wonTitle.value || '-'), 1)])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showCashRes.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "cash-wheel__box",
          onClick: _closeCashRes
        }, [_createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('Take a REWARD')), 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('Congratulati')), 1), _createElementVNode("p", _hoisted_14, _toDisplayString(prizeMoney.value || '-'), 1)])])]),
        _: 1
      }, 8, ["show"]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Open the card')), 1), _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.$t('and select an attraction.')), 1), _cache[27] || (_cache[27] = _createStaticVNode("<div class=\"booking-avatar__box\" data-v-72e9d532><img src=\"" + _imports_2 + "\" alt=\"\" data-v-72e9d532><img src=\"" + _imports_3 + "\" alt=\"\" data-v-72e9d532><img src=\"" + _imports_2 + "\" alt=\"\" data-v-72e9d532><img src=\"" + _imports_2 + "\" alt=\"\" data-v-72e9d532><div class=\"booking-index__\" data-v-72e9d532>10k+</div></div>", 1)), _createElementVNode("div", _hoisted_18, [_cache[26] || (_cache[26] = _createElementVNode("img", {
        src: _imports_4,
        alt: ""
      }, null, -1)), _createVNode(_component_van_notice_bar, {
        style: {
          "padding": "0",
          "background-color": "transparent"
        },
        text: broadcastList.value
      }, null, 8, ["text"])])])]), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, [_createElementVNode("img", {
        src: _imports_5,
        alt: "",
        onClick: _cache[3] || (_cache[3] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_6,
        alt: "",
        onClick: _cache[4] || (_cache[4] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_7,
        alt: "",
        onClick: _cache[5] || (_cache[5] = $event => _Buy($event))
      })]), _createElementVNode("div", _hoisted_21, [_createElementVNode("img", {
        src: _imports_8,
        alt: "",
        onClick: _cache[6] || (_cache[6] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_9,
        alt: "",
        onClick: _cache[7] || (_cache[7] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_10,
        alt: "",
        onClick: _cache[8] || (_cache[8] = $event => _Buy($event))
      })]), _createElementVNode("div", _hoisted_22, [_createElementVNode("img", {
        src: _imports_11,
        alt: "",
        onClick: _cache[9] || (_cache[9] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_12,
        alt: "",
        onClick: _cache[10] || (_cache[10] = $event => _Buy($event))
      }), _createElementVNode("img", {
        src: _imports_13,
        alt: "",
        onClick: _cache[11] || (_cache[11] = $event => _Buy($event))
      })])]), _cache[43] || (_cache[43] = _createElementVNode("div", {
        style: {
          "height": "52px"
        }
      }, null, -1)), _createElementVNode("div", {
        class: _normalizeClass(["booking-foot__", {
          'booking-foot__active': showFoot.value
        }])
      }, [_createElementVNode("div", {
        class: "booking-foot__head",
        onClick: showFootHandle
      }, [showFoot.value ? (_openBlock(), _createElementBlock("img", _hoisted_23)) : (_openBlock(), _createElementBlock("img", _hoisted_24)), _createElementVNode("p", null, _toDisplayString(showFoot.value ? _ctx.$t('Click to collapse details') : _ctx.$t('View more product details')), 1)]), _withDirectives(_createElementVNode("div", _hoisted_25, [_createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.$t('Scenic spot')), 1), _cache[28] || (_cache[28] = _createStaticVNode("<div class=\"booking-foot__content\" data-v-72e9d532><img src=\"" + _imports_16 + "\" alt=\"\" class=\"mb-12\" data-v-72e9d532><div class=\"booking-foot__row mb-24\" data-v-72e9d532><div data-v-72e9d532><img src=\"" + _imports_17 + "\" alt=\"\" data-v-72e9d532></div><div data-v-72e9d532><img src=\"" + _imports_18 + "\" alt=\"\" data-v-72e9d532></div></div><img src=\"" + _imports_19 + "\" alt=\"\" class=\"mb-12\" data-v-72e9d532><div class=\"booking-foot__row\" data-v-72e9d532><div data-v-72e9d532><img src=\"" + _imports_20 + "\" alt=\"\" data-v-72e9d532></div><div data-v-72e9d532><img src=\"" + _imports_21 + "\" alt=\"\" data-v-72e9d532></div></div></div>", 1))], 512), [[_vShow, showFoot.value]])], 2), _createVNode(_component_van_overlay, {
        show: showOrder.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "booking-order__",
          onClick: _cache[13] || (_cache[13] = $event => showOrder.value = false)
        }, [_createElementVNode("div", {
          onClick: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("p", _hoisted_27, _toDisplayString(orderData.value.type == 1 ? 'Regular orderaction' : 'Special order'), 1), _createElementVNode("div", {
          class: _normalizeClass(["booking-order__content", {
            'booking-order__bg': orderData.value.type === 2
          }])
        }, [_createElementVNode("div", _hoisted_28, [orderData.value.type == 1 ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _unref(baseURL) + '/storage/' + orderData.value.info.picture,
          alt: ""
        }, null, 8, _hoisted_29)) : orderData.value.type == 2 ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _unref(baseURL) + '/storage/' + orderData.value.info.product_list[0].product.picture,
          alt: ""
        }, null, 8, _hoisted_30)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_31, [_createElementVNode("div", _hoisted_32, [_createElementVNode("div", _hoisted_33, [_createElementVNode("p", _hoisted_34, [_cache[29] || (_cache[29] = _createElementVNode("img", {
          src: _imports_22,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(orderData.value.type == 2 ? orderData.value.info.product_list[0].product.rate : orderData.value.info.rate), 1), _cache[30] || (_cache[30] = _createElementVNode("span", null, "(-)", -1))]), orderData.value.type == 2 ? (_openBlock(), _createElementBlock("span", _hoisted_35, "x" + _toDisplayString(orderData.value.info.all_num) + " " + _toDisplayString(_ctx.$t('commission')), 1)) : _createCommentVNode("", true)]), _createElementVNode("p", _hoisted_36, _toDisplayString(orderData.value.info.title), 1)]), _cache[31] || (_cache[31] = _createElementVNode("div", {
          class: "booking-order__line"
        }, null, -1)), _createElementVNode("div", _hoisted_37, [_createElementVNode("div", _hoisted_38, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order Number')) + ":", 1), _createElementVNode("span", null, _toDisplayString(orderData.value.order_no), 1)]), _createElementVNode("div", _hoisted_39, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order time')) + ":", 1), _createElementVNode("span", null, _toDisplayString(_unref(dayjs).unix(orderData.value.order_time).format('YYYY-MM-DD')), 1)]), _createElementVNode("div", _hoisted_40, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order price')) + ":", 1), _createElementVNode("span", null, "₹ " + _toDisplayString(orderData.value.order_price), 1)]), _createElementVNode("div", _hoisted_41, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Total Return')) + ":", 1), _createElementVNode("span", null, "₹ " + _toDisplayString(orderData.value.total_return), 1)]), _createElementVNode("div", _hoisted_42, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Comm')) + ":", 1), _createElementVNode("span", null, _toDisplayString(orderData.value.commit * 100) + "%", 1)]), orderData.value.type === 1 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "booking-order__btn",
          onClick: _ConfirmOrder
        }, _toDisplayString(_ctx.$t('Submit')), 1)) : _createCommentVNode("", true)]), orderData.value.type === 2 ? (_openBlock(), _createElementBlock("div", _hoisted_43, [_createElementVNode("img", {
          src: _imports_23,
          alt: "",
          onClick: _ConfirmOrder
        })])) : _createCommentVNode("", true)])], 2)])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showKaDan.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "booking-order__",
          onClick: _cache[15] || (_cache[15] = $event => showKaDan.value = false)
        }, [_createElementVNode("div", {
          onClick: _cache[14] || (_cache[14] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("p", _hoisted_44, _toDisplayString(orderData.value.type == 1 ? 'Regular orderaction' : 'Special order'), 1), _createElementVNode("div", {
          class: _normalizeClass(["booking-order__content", {
            'booking-order__bg': orderData.value.type === 2
          }])
        }, [_createElementVNode("div", _hoisted_45, [_createElementVNode("img", {
          src: _unref(baseURL) + '/storage/' + orderData.value.info.pack.product_list[0].product.picture,
          alt: ""
        }, null, 8, _hoisted_46)]), _createElementVNode("div", _hoisted_47, [_createElementVNode("div", _hoisted_48, [_createElementVNode("div", _hoisted_49, [_createElementVNode("p", _hoisted_50, [_cache[32] || (_cache[32] = _createElementVNode("img", {
          src: _imports_22,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(orderData.value.info.pack.product_list[0].product.rate), 1), _cache[33] || (_cache[33] = _createElementVNode("span", null, "(-)", -1))]), _createElementVNode("span", null, "x" + _toDisplayString(orderData.value.info.all_num) + " " + _toDisplayString(_ctx.$t('commission')), 1)]), _createElementVNode("p", _hoisted_51, _toDisplayString(orderData.value.info.category.name), 1)]), _cache[34] || (_cache[34] = _createElementVNode("div", {
          class: "booking-order__line"
        }, null, -1)), _createElementVNode("div", _hoisted_52, [_createElementVNode("div", _hoisted_53, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order Number')) + ":", 1), _createElementVNode("span", null, _toDisplayString(orderData.value.order_no), 1)]), _createElementVNode("div", _hoisted_54, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order time')) + ":", 1), _createElementVNode("span", null, _toDisplayString(_unref(dayjs).unix(orderData.value.order_time).format('YYYY-MM-DD')), 1)]), _createElementVNode("div", _hoisted_55, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Product')) + ":", 1), _createElementVNode("span", _hoisted_56, _toDisplayString(orderData.value.info.pack.product_list[0].product.title), 1)]), _createElementVNode("div", _hoisted_57, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Order price')) + ":", 1), _createElementVNode("span", null, "₹ " + _toDisplayString(orderData.value.order_price), 1)]), _createElementVNode("div", _hoisted_58, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Total Return')) + ":", 1), _createElementVNode("span", null, "₹ " + _toDisplayString(orderData.value.total_return), 1)]), _createElementVNode("div", _hoisted_59, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('Comm')) + ":", 1), _createElementVNode("span", null, _toDisplayString(orderData.value.commit * 100) + "%", 1)])]), _createElementVNode("div", {
          class: "booking-btn__img"
        }, [_createElementVNode("img", {
          src: _imports_23,
          alt: "",
          onClick: _ConfirmOrder
        })])])], 2)])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showConfirm.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_60, [_createElementVNode("div", _hoisted_61, [_createElementVNode("p", null, _toDisplayString(_ctx.$t('Insufficient balance, Please go to recharge.')), 1), _createElementVNode("div", _hoisted_62, [_createElementVNode("div", {
          onClick: _cache[16] || (_cache[16] = $event => showConfirm.value = false)
        }, _toDisplayString(_ctx.$t('Cancel')), 1), _createElementVNode("div", null, _toDisplayString(_ctx.$t('Confirm')), 1)])])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showCard.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "booking-card",
          onClick: _cache[21] || (_cache[21] = $event => showCard.value = false)
        }, [_createElementVNode("p", _hoisted_63, _toDisplayString(_ctx.$t('Pick a bag to reward')), 1), _createElementVNode("div", {
          class: "booking-card__content",
          onClick: _cache[20] || (_cache[20] = _withModifiers(() => {}, ["stop"]))
        }, [_createElementVNode("div", {
          class: "card",
          ref_key: "card1",
          ref: card1,
          onClick: _cache[17] || (_cache[17] = $event => tapCard(0))
        }, [_cache[36] || (_cache[36] = _createElementVNode("div", {
          class: "card-face"
        }, null, -1)), _createElementVNode("div", _hoisted_64, [_cache[35] || (_cache[35] = _createElementVNode("p", {
          class: "card-back__title"
        }, "N.01", -1)), winIndex.value === 0 ? (_openBlock(), _createElementBlock("p", _hoisted_65, _toDisplayString(_ctx.$t('Congratulati')), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_66, _toDisplayString(cardData.value.length ? cardData.value[0].title : '-'), 1)])], 512), _createElementVNode("div", {
          class: "card",
          ref_key: "card2",
          ref: card2,
          onClick: _cache[18] || (_cache[18] = $event => tapCard(1))
        }, [_cache[38] || (_cache[38] = _createElementVNode("div", {
          class: "card-face"
        }, null, -1)), _createElementVNode("div", _hoisted_67, [_cache[37] || (_cache[37] = _createElementVNode("p", {
          class: "card-back__title"
        }, "N.02", -1)), winIndex.value === 1 ? (_openBlock(), _createElementBlock("p", _hoisted_68, _toDisplayString(_ctx.$t('Congratulati')), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_69, _toDisplayString(cardData.value.length ? cardData.value[1].title : '-'), 1)])], 512), _createElementVNode("div", {
          class: "card",
          ref_key: "card3",
          ref: card3,
          onClick: _cache[19] || (_cache[19] = $event => tapCard(2))
        }, [_cache[40] || (_cache[40] = _createElementVNode("div", {
          class: "card-face"
        }, null, -1)), _createElementVNode("div", _hoisted_70, [_cache[39] || (_cache[39] = _createElementVNode("p", {
          class: "card-back__title"
        }, "N.03", -1)), winIndex.value === 2 ? (_openBlock(), _createElementBlock("p", _hoisted_71, _toDisplayString(_ctx.$t('Congratulati')), 1)) : _createCommentVNode("", true), _createElementVNode("p", _hoisted_72, _toDisplayString(cardData.value.length ? cardData.value[2].title : '-'), 1)])], 512)])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showMsg.value,
        "custom-style": {
          background: 'transparent'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "booking-msg",
          onClick: _cache[23] || (_cache[23] = $event => showMsg.value = false)
        }, [_createElementVNode("div", {
          class: "booking-msg__content",
          onClick: _cache[22] || (_cache[22] = _withModifiers(() => {}, ["stop"]))
        }, [_cache[41] || (_cache[41] = _createElementVNode("img", {
          src: _imports_24,
          alt: ""
        }, null, -1)), _createElementVNode("span", null, _toDisplayString(_ctx.$t('You have finished rating')), 1)])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_overlay, {
        show: showWallet.value
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "booking-wallet",
          onClick: _cache[25] || (_cache[25] = $event => showWallet.value = false)
        }, [_createElementVNode("div", {
          class: "booking-wallet__content",
          onClick: _cache[24] || (_cache[24] = _withModifiers(() => {}, ["stop"]))
        }, [_cache[42] || (_cache[42] = _createElementVNode("img", {
          src: _imports_25,
          alt: ""
        }, null, -1)), _createElementVNode("div", _hoisted_73, [_createElementVNode("p", _hoisted_74, _toDisplayString(_ctx.$t('Account details')), 1), _createElementVNode("div", _hoisted_75, [_createElementVNode("p", null, "₹ " + _toDisplayString(Number(user.value.trial_bouns).toFixed(2)), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('Trial bonus')), 1)]), _createElementVNode("div", _hoisted_76, [_createElementVNode("p", _hoisted_77, _toDisplayString(_ctx.$t('Account balance')), 1), _createElementVNode("p", _hoisted_78, "₹ " + _toDisplayString(Number(user.value.balance).toFixed(2)), 1)]), _createElementVNode("div", _hoisted_79, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_80, _toDisplayString(_ctx.$t("Today's Commissions")), 1), _createElementVNode("p", _hoisted_81, "₹ " + _toDisplayString(Number(user.value.kickback).toFixed(2)), 1)]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_82, _toDisplayString(_ctx.$t('On hold amount')), 1), _createElementVNode("p", _hoisted_83, "₹ " + _toDisplayString(Number(user.value.freeze_amount).toFixed(2)), 1)]), _createElementVNode("div", null, [_createElementVNode("p", _hoisted_84, _toDisplayString(_ctx.$t('Number of orders today')), 1), _createElementVNode("p", _hoisted_85, _toDisplayString(user.value.step), 1)])])])])])]),
        _: 1
      }, 8, ["show"])])], 64);
    };
  }
};